.HeaderContainer{
    background-color: #102B8B;
    display: block;
    height: auto;
    padding-left: 10vw;
    padding-right: 10vw;
    /* display: grid;
    grid-template-rows: 1fr 2fr; */
}

.HeaderLogo{
    border-bottom: 1px solid #FFFFFF;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
    color: #FFFFFF;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.HeaderLogo #logo{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 102.5%;
}

#logo img{
    width: 120px;
}

.HeaderLogo #language{
    justify-self: flex-end;
}

#HeaderMenu{
    padding-top: 20px;
    padding-bottom: 5px;
    text-align: center;

    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 102.5%;

    color: #FFFFFF;
}

#HeaderMenu span{
    margin-right: 30px;
}

.activePage{
    color: #E1E500;
    border-bottom: 4px solid #E1E500;
}

#HeaderMenu span:hover{
    cursor: pointer;
}

#dropdown{
    background-color: transparent !important;
    border-color: transparent !important;
}

a{
    text-decoration: none;
    color: inherit;
}

a:hover{
    text-decoration: none;
    color: inherit;
}

#logo img{
    user-select: none;
}

@media(max-width: 800px){
    #logo img{
        width: 105px;
        user-select: none;
    }

    .HeaderContainer{
        padding-left: 2vw;
        padding-right: 2vw;
    }

    .HeaderLogo{
        border-bottom: none;
    }
}

@media(min-width: 1500px){
    #logo img{
        width: 200px;
        user-select: none;
    }
}



/* _____________________________________________ */

.dropbtn {
    color: white;
    margin-right: 30px;
    border: none;
  }
  
  .dropdown {
    z-index: 3;
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 260px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
  
.dropdown-content a:hover {
    background-color: #E1E500;
}
  
.dropdown:hover .dropdown-content{
    display: block;
}

.dropdown-content:hover{
    display: block;
}
  
.dropdown:hover .dropbtn {
    color: #E1E500;
    border-bottom: 4px solid #E1E500;
}