.FooterContainer{
    background-color: #102B8B;
    display: grid;
    min-height: 25vh;
    grid-template-rows: 2fr 1fr;
}

#mailLink{
    justify-self: center;
}

.footerText{
    align-self: center;
    color: white;
    justify-self: center;
}