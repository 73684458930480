/* SideBar */
.sidenav {
    height: 100%;
    width: 350px;
    transform: translateX(-350px);
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #102B8B;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

@media(max-width: 900px){
    .sidenav {
        width: 100%;
        transform: translateX(100%);
    }
}
.openMenuButton{
    display: block;
    height: 50px;
    width: 50px;
    text-align:center;
    background-color: #E1E500;
    border-radius: 50%;
    backdrop-filter: blur(18px);
}

.openMenuButton img{
    width: 20px;
    margin-bottom: 3px;
}


.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: white;
    display: block;
    transition: 0.3s;
}
.sidenav a:hover {
    color: #E1E500;
} 

.sidenav .closebtn {
    position: absolute;
    top: 0;
    left: -5px;
    font-size: 36px;
    margin-left: 50px;
}
#main {
    transition: margin-right .5s;
    padding: 16px;
}
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}





/* Navbar */
.MainNavbar{
    position: relative;
    background-color: transparent;  
    z-index: 2;
    top: 0;
    width: 100%;
    max-height: 5% !important;
}