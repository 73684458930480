@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;500;600;700&display=swap');
:root{
  --ff-blue: #102B8B;
  --ff-yellow: #E1E500;
  --ff-white: #FFFFFF;
}

body{
  padding: 0;
  margin: 0;
}