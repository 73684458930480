.carousel-indicators button {
    height: 10px !important;
    width: 10px !important;
    margin: 0 10px !important;
    border-radius: 100%;
    background-color: var(--ff-yellow)!important;
}

.carousel{
    user-select: none;
    margin: 0 !important;
    padding: 0 !important;
}


#Myslides{
    z-index: 1;
    min-height: 30vh !important;
}


.slideImage{
    /* width: 100%; */
    min-height: 500px;
    max-height: 500px !important;
    object-fit: cover !important;
}

@media (min-width: 1600px) {
    .slideImage{
        min-height: 900px;
        max-height: 900px !important;
        object-fit: cover !important;
    }
}


@media (max-width: 800px) {
    .slideImage{
        min-height: 200px;
        max-height: 200px !important;
        object-fit: cover !important;
    }
}

