.myBtn{
  background-color: #e8b923;

  border: 1px solid black;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 2vw;
    padding-right: 2vw;
    border-radius: 94px;
    text-align: center;

    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    grid-row: 2;
    margin-top: 50px;

    /* align-self: center; */
}

.myBtn:hover{
  background-image: linear-gradient(
  40deg,
  hsl(0deg 56% 38%) 0%,
  hsl(0deg 56% 38%) 12%,
  hsl(1deg 48% 50%) 28%,
  hsl(0deg 100% 73%) 45%,
  hsl(0deg 44% 44%) 62%,
  hsl(0deg 50% 30%) 81%,
  hsl(0deg 50% 30%) 100%
);
}

@media (max-width: 1110px){
    .myBtn{
        grid-column-start: 1;
        grid-column-end: 3;
    }
  }

@media (max-width: 800px){
    .myBtn{
        grid-column-start: 1;
        grid-column-end: 6;
    }
  }

@media (max-width: 595px){
    .myBtn{
        align-self: center;
        border-radius: 94px;
        text-align: center;
    
        font-family: 'Jost';
        font-style: normal;
        font-weight: 600;
        grid-row: 2;
    }
  }
  
@media (max-width: 400px){
    .TextHeader{
      font-size: 32px;
    }
    
    .MainText{
      font-size: 15px;
      line-height: 22px;
    }
}

.myBtn:hover{
    text-decoration: underline;
    cursor: pointer;
}

.bookletHeader{
    margin-top: 6vh;
}


.booklet{
    margin-top: 4vh;
    border-bottom: 1px solid #C1C1C1;
    padding-bottom: 4vh;

    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr; */
}

.rankingLink{
    grid-column-start: 1;
    grid-column-end: 6;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    color: #102B8B;
    height: auto;
}

.fileView{
  grid-row: 3;
  min-height: 60vh;
  margin-top: 150px;
  width: 100%;
}


@media screen and (max-width: 600px) {
  .test td {
    display: flex !important;
  }
  
  .test td::before {
    content: attr(label) !important;
    font-weight: bold !important;
    font-size: 12px;
    width: 120px !important;
    border-right: 1px solid black;
    min-width: 120px !important;
  }

  .test tr td{
    width: auto !important;
    border: 1px solid black !important;
    border-bottom: none !important;
  }

  .test tr {
    border-top: 2px solid black !important;
    margin-bottom: 8px;
  }

  .test tr td span:first-child {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  colgroup {
    display: none;
  }
}