.myContainer{
  position: relative;
  width: 85vw;
  justify-self: center;
  margin: 0 auto;
  background-image: linear-gradient(to right,#bf953f,#e0b828,#bf953f,#fbf5b7,#aa771c, #bf953f);
}

@media (max-width: 800px){
  .myContainer{
    padding: 0 !important;
    display: block;
    width: 100vw;
  }

  body{
    background-image: linear-gradient(to right,#bf953f,#e0b828,#bf953f,#fbf5b7,#aa771c, #bf953f);
  }
}

.TextHeader{
  font-family: 'Jost-Bold', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 52px;
  line-height: 102.5%;

  color: #000000;
}

.MainText{
  margin-top: 5vh;
  /* font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 29px; */
  color: #141414;
}

.TextInfo{
  padding: 10%;
}

@media (max-width: 600px){
  .TextHeader{
    font-size: 42px;
  }

  .MainText{
    font-size: 20px;
    line-height: 29px;
  }
}

@media (max-width: 400px){
  .TextHeader{
    font-size: 32px;
  }

  .MainText{
    font-size: 15px;
    line-height: 22px;
  }
}

/* ::selection {
  background: #E1E500;
  color: #102B8B;
} */