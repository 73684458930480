

@media (max-width: 990px){
    .AboutInfo{
        /* padding: 0%;
        padding-top: 10%; */

    }
    .MainText img{  
        max-width: 100vw;
    }

   
}
  
.MainText img{
    max-width: 40vw;
}

