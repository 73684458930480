.myBtn{
    background-color: #e8b923;
    border: 1px solid black;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 2vw;
    padding-right: 2vw;
    border-radius: 94px;
    text-align: center;

    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    grid-row: 2;
    margin-top: 50px;
}


.subGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
}

#form{
    grid-column-start: 2 !important;
    grid-column-end: 2 !important;
    align-self: end;
}

#formBtn{
    grid-column-start: 2 !important;
    grid-column-end: 2 !important;
    align-self: start;
}


@media (max-width: 1500px){
    .subGrid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
    
    #form{
        grid-column-start: 1 !important;
        grid-column-end: 3 !important;
        align-self: end;
    }
    
    #formBtn{
        grid-column-start: 1 !important;
        grid-column-end: 3 !important;
        align-self: start;
    }
}

@media (max-width: 1500px){
    .subGrid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
    
    #form{
        grid-column-start: 1 !important;
        grid-column-end: 4 !important;
        align-self: end;
    }
    
    #formBtn{
        grid-column-start: 1 !important;
        grid-column-end: 4 !important;
        align-self: start;
    }
}